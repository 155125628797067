var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card card-custom gutter-b"},[_vm._m(0),_c('div',{staticClass:"card-body pt-0"},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-lg-6"},[_c('b-button',{attrs:{"href":this.url +
              '/rekomendasi_peserta?id_peserta=' +
              _vm.user.id_ptsp +
              '&tipe=ppb_pemda&export=yes&tahun=2023',"variant":"primary"}},[_c('i',{staticClass:"fa fa-download",attrs:{"aria-hidden":"true"}}),_vm._v("Excel ")])],1),_c('div',{staticClass:"col-lg-6"})]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_vm._m(1),_c('tbody',[_c('tr',[_c('td',{attrs:{"rowspan":"9"}},[_vm._v(_vm._s(_vm.datarekomendasi[0].kriteria))]),_c('td',{attrs:{"rowspan":"6"}},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].indikator)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[0].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[0].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[0].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[0].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[0].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[1].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[0].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[1].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[0].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[1].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[2].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[0].pertanyaan[2].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[2].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[0].pertanyaan[2].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[2].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[3].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[0].pertanyaan[3].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[3].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[0].pertanyaan[3].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[3].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[4].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[0].pertanyaan[4].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[4].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[0].pertanyaan[4].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[4].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[5].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[0].pertanyaan[5].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[5].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[0].pertanyaan[5].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[0].pertanyaan[5].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.datarekomendasi[0].indikator[1].indikator))]),_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[1].pertanyaan[0].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[1].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[1].pertanyaan[0].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[1].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[1].pertanyaan[0].rekomendasi)+" ")])]),_c('tr',[_c('td',{attrs:{"rowspan":"2"}},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[2].indikator)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[2].pertanyaan[0].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[2].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[2].pertanyaan[0].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[2].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[2].pertanyaan[0].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[2].pertanyaan[1].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[2].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[2].pertanyaan[1].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[0].indikator[2].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[0].indikator[2].pertanyaan[1].rekomendasi)+" ")])]),_c('tr',[_c('td',{attrs:{"rowspan":"26"}},[_vm._v(_vm._s(_vm.datarekomendasi[1].kriteria))]),_c('td',{attrs:{"rowspan":"6"}},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].indikator)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[0].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[0].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[0].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[0].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[0].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[1].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[0].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[1].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[0].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[1].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[2].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[0].pertanyaan[2].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[2].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[0].pertanyaan[2].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[2].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[3].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[0].pertanyaan[3].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[3].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[0].pertanyaan[3].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[3].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[4].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[0].pertanyaan[4].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[4].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[0].pertanyaan[4].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[4].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[5].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[0].pertanyaan[5].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[5].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[0].pertanyaan[5].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[0].pertanyaan[5].rekomendasi)+" ")])]),_c('tr',[_c('td',{attrs:{"rowspan":"6"}},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].indikator)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[0].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[1].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[0].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[1].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[0].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[1].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[1].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[1].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[1].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[1].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[2].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[1].pertanyaan[2].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[2].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[1].pertanyaan[2].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[2].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[3].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[1].pertanyaan[3].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[3].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[1].pertanyaan[3].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[3].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[4].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[1].pertanyaan[4].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[4].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[1].pertanyaan[4].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[4].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[5].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[1].pertanyaan[5].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[5].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[1].pertanyaan[5].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[1].pertanyaan[5].rekomendasi)+" ")])]),_c('tr',[_c('td',{attrs:{"rowspan":"6"}},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].indikator)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[0].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[2].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[0].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[2].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[0].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[1].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[2].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[1].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[2].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[1].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[2].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[2].pertanyaan[2].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[2].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[2].pertanyaan[2].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[2].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[3].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[2].pertanyaan[3].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[3].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[2].pertanyaan[3].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[3].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[4].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[2].pertanyaan[4].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[4].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[2].pertanyaan[4].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[4].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[5].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[2].pertanyaan[5].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[5].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[2].pertanyaan[5].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[2].pertanyaan[5].rekomendasi)+" ")])]),_c('tr',[_c('td',{attrs:{"rowspan":"6"}},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].indikator)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[0].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[3].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[0].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[3].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[0].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[1].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[3].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[1].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[3].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[1].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[2].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[3].pertanyaan[2].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[2].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[3].pertanyaan[2].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[2].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[3].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[3].pertanyaan[3].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[3].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[3].pertanyaan[3].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[3].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[4].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[3].pertanyaan[4].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[4].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[3].pertanyaan[4].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[4].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[5].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[3].pertanyaan[5].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[5].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[3].pertanyaan[5].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[3].pertanyaan[5].rekomendasi)+" ")])]),_c('tr',[_c('td',{attrs:{"rowspan":"2"}},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[4].indikator)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[4].pertanyaan[0].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[4].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[4].pertanyaan[0].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[4].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[4].pertanyaan[0].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[4].pertanyaan[1].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[4].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[4].pertanyaan[1].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[1].indikator[4].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[1].indikator[4].pertanyaan[1].rekomendasi)+" ")])]),_c('tr',[_c('td',{attrs:{"rowspan":"12"}},[_vm._v(_vm._s(_vm.datarekomendasi[2].kriteria))]),_c('td',{attrs:{"rowspan":"3"}},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[0].indikator)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[0].pertanyaan[0].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[0].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[0].pertanyaan[0].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[0].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[0].pertanyaan[0].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[0].pertanyaan[1].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[0].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[0].pertanyaan[1].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[0].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[0].pertanyaan[1].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[0].pertanyaan[2].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[0].pertanyaan[2].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[0].pertanyaan[2].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[0].pertanyaan[2].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[0].pertanyaan[2].rekomendasi)+" ")])]),_c('tr',[_c('td',{attrs:{"rowspan":"5"}},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[1].indikator)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[1].pertanyaan[0].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[1].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[1].pertanyaan[0].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[1].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[1].pertanyaan[0].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[1].pertanyaan[1].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[1].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[1].pertanyaan[1].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[1].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[1].pertanyaan[1].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[1].pertanyaan[2].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[1].pertanyaan[2].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[1].pertanyaan[2].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[1].pertanyaan[2].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[1].pertanyaan[2].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[1].pertanyaan[3].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[1].pertanyaan[3].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[1].pertanyaan[3].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[1].pertanyaan[3].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[1].pertanyaan[3].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[1].pertanyaan[4].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[1].pertanyaan[4].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[1].pertanyaan[4].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[1].pertanyaan[4].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[1].pertanyaan[4].rekomendasi)+" ")])]),_c('tr',[_c('td',{attrs:{"rowspan":"2"}},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[2].indikator)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[2].pertanyaan[0].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[2].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[2].pertanyaan[0].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[2].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[2].pertanyaan[0].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[2].pertanyaan[1].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[2].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[2].pertanyaan[1].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[2].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[2].pertanyaan[1].rekomendasi)+" ")])]),_c('tr',[_c('td',{attrs:{"rowspan":"2"}},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[3].indikator)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[3].pertanyaan[0].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[3].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[3].pertanyaan[0].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[3].pertanyaan[0].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[3].pertanyaan[0].rekomendasi)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[3].pertanyaan[1].pertanyaan)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[3].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[3].pertanyaan[1].jawaban)+" ")]),_c('td',{staticClass:"warna",style:('background-color:' +
                  _vm.datarekomendasi[2].indikator[3].pertanyaan[1].color)},[_vm._v(" "+_vm._s(_vm.datarekomendasi[2].indikator[3].pertanyaan[1].rekomendasi)+" ")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header border-0"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v("Data Rekomendasi PPB")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Kriteria Penilaian")]),_c('th',[_vm._v("Indikator Penilaian")]),_c('th',[_vm._v("Tolak Ukur")]),_c('th',[_vm._v("Hasil Verifikasi")]),_c('th',[_vm._v("Rekomendasi")])])])
}]

export { render, staticRenderFns }